var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hk_market"},[_c('img-div',{attrs:{"class-name":"flex flex-hc","img-url":_vm.$t(("marketMall." + _vm.market + "Market.banner")),"height":"300"}}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"table fs-18"},[_c('div',{staticClass:"thead fw-600"},[_c('div',{staticClass:"tr flex"},_vm._l((_vm.$t(
              ("marketMall." + _vm.market + "Market.use_table.head")
            )),function(head,i){return _c('div',{key:i,staticClass:"td flex1 flex flex-hc flex-vc"},[_vm._v(" "+_vm._s(head)+" ")])}),0)]),_c('div',{staticClass:"tbody"},_vm._l((_vm.$t(
            ("marketMall." + _vm.market + "Market.use_table.body")
          )),function(body,idx){return _c('div',{key:idx,staticClass:"tr flex"},_vm._l((body.td),function(td,bodyI){return _c('div',{key:idx + bodyI,class:[
              'td flex1 flex flex-hc flex-vc',
              bodyI === 0 ? 'fw-600 bg_lightBlue' : '' ]},[(td.iconName)?_c('svg-icon',{style:(("color:var(--" + (td.iconName === 'right_fill' ? 'greenColor' : 'redColor') + ")")),attrs:{"name":td.iconName,"size":18,"icon-class":"use_iconClass"}}):_vm._e(),_c('span',[_vm._v(_vm._s(td.name))])],1)}),0)}),0)]),_c('div',{staticClass:"use_img_list flex flex-jsb"},_vm._l((_vm.$t(
          ("marketMall." + _vm.market + "Market.use_img_list")
        )),function(use_img_list_item,use_img_list_i){return _c('div',{key:use_img_list_i,staticClass:"use_img_list_item flex flex-v flex-vc"},[_c('img',{staticClass:"top_img",attrs:{"src":use_img_list_item.top_img,"alt":""}}),_c('div',{staticClass:"bottom",style:(("" + (_vm.market === 'us' ? 'padding:0px 63px;' : '')))},[_c('img',{staticClass:"bottom_img",attrs:{"src":use_img_list_item.bottom_img,"alt":""}})])])}),0),_c('div',{staticClass:"flex flex-v flex-vc"},[_c('img',{staticClass:"huikui_title",attrs:{"src":_vm.$t(("marketMall." + _vm.market + "Market.huikui_title")),"alt":""}}),_c('span',{staticClass:"huikui_desc"},[_vm._v(" "+_vm._s(_vm.$t(("marketMall." + _vm.market + "Market.huikui_desc")))+" "),_c('span',[_vm._v(_vm._s(_vm.$t(("marketMall." + _vm.market + "Market.huikui_desc_tip"))))])])]),_c('div',{staticClass:"huikui_list flex flex-jsb fs-18"},_vm._l((_vm.$t(
          ("marketMall." + _vm.market + "Market.huikui_list_item")
        )),function(huikui_list_item,huikui_list_item_i){return _c('div',{key:huikui_list_item_i,staticClass:"huikui_list_item"},[_c('div',{staticClass:"item_title fs-24 fw-600"},[_vm._v(" "+_vm._s(huikui_list_item.title)+" ")]),_c('div',{staticClass:"flex flex-jsb flex-vc",staticStyle:{"padding":"0 51px"}},[_c('div',{staticClass:"flex flex-v flex-vc"},[_vm._m(0,true),_c('span',{staticClass:"item_img_title"},[_vm._v(_vm._s(huikui_list_item.phone_title))])]),_c('span',{staticClass:"fs-16",staticStyle:{"margin-top":"-40px"}},[_vm._v(_vm._s(huikui_list_item.or))]),_c('div',{staticClass:"flex flex-v flex-vc"},[_vm._m(1,true),_c('span',{staticClass:"item_img_title"},[_vm._v(_vm._s(huikui_list_item.pc_title))])])]),_c('div',{staticClass:"item_desc"},[_vm._v(" "+_vm._s(huikui_list_item.desc)+" "),_c('span',{staticClass:"item_tip fs-14"},[_vm._v(" "+_vm._s(huikui_list_item.tip)+" ")])])])}),0),_c('el-button',{staticClass:"question_button",attrs:{"type":"primary","plain":"","round":""},on:{"click":function($event){_vm.dialogVisible = !_vm.dialogVisible}}},[_vm._v(_vm._s(_vm.$t(("marketMall." + _vm.market + "Market.questionButton"))))])],1),_c('div',{staticClass:"buy_now"},[_c('el-button',{staticClass:"buy_button",attrs:{"type":"primary"},on:{"click":_vm.goBuy}},[_vm._v(_vm._s(_vm.$t(("marketMall." + _vm.market + "Market.buy_now"))))])],1),_c('empty-dialog',{attrs:{"width":"560px"},model:{value:(_vm.dialogVisible),callback:function ($$v) {_vm.dialogVisible=$$v},expression:"dialogVisible"}},[_c('div',{staticClass:"question_content"},[_c('div',{staticClass:"dialog_title"},[_vm._v(" "+_vm._s(_vm.$t(("marketMall." + _vm.market + "Market.question_title")))+" ")]),_c('div',{staticClass:"answer_body"},_vm._l((_vm.$t(
            ("marketMall." + _vm.market + "Market.question_list")
          )),function(question,questionI){return _c('div',{key:questionI},[_c('div',{staticClass:"q"},[_vm._v(" "+_vm._s(question.q)+" ")]),_c('div',{staticClass:"a"},[_vm._v(" "+_vm._s(question.a)+" ")])])}),0),_c('div',{staticClass:"answer_footer"},[_vm._v(" "+_vm._s(_vm.$t(("marketMall." + _vm.market + "Market.answer_footer")))+" ")])])]),_c('empty-dialog',{attrs:{"width":"560px"},model:{value:(_vm.showPiStatus),callback:function ($$v) {_vm.showPiStatus=$$v},expression:"showPiStatus"}},[_c('div',{staticClass:"piStatus_content question_content"},[_c('div',{staticClass:"dialog_title"},[_vm._v(" "+_vm._s(_vm.$t(("marketMall." + _vm.market + "Market.piStatus_title")))+" ")]),_c('div',{staticClass:"answer_body piStatus_body",domProps:{"innerHTML":_vm._s(_vm.$t(("marketMall." + _vm.market + "Market.piStatus_content")))}}),_c('div',{staticClass:"piStatus_footer flex flex-vc flex-jend"},[_c('el-button',{staticClass:"buy_button",on:{"click":function($event){return _vm.choosePi(1)}}},[_vm._v(_vm._s(_vm.$t(("marketMall." + _vm.market + "Market.piStatus_lButton"))))]),_c('el-button',{staticClass:"buy_button",attrs:{"type":"primary"},on:{"click":function($event){return _vm.choosePi(0)}}},[_vm._v(_vm._s(_vm.$t(("marketMall." + _vm.market + "Market.piStatus_RButton"))))])],1)])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item_img"},[_c('img',{staticClass:"right_top_icon",attrs:{"src":require("@/assets/v2/marketMall/fan@3x.png"),"alt":""}}),_c('img',{staticClass:"main_img",attrs:{"src":require("@/assets/v2/marketMall/phone.png"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item_img"},[_c('img',{staticClass:"right_top_icon",attrs:{"src":require("@/assets/v2/marketMall/fan@3x.png"),"alt":""}}),_c('img',{staticClass:"main_img",attrs:{"src":require("@/assets/v2/marketMall/pc.png"),"alt":""}})])}]

export { render, staticRenderFns }